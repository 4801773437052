body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF;
  color: #191919;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Inter400';
  line-height: 'normal';
  src: url('../src/assets/fonts/Inter_24pt-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter500';
  src: url('../src/assets/fonts/Inter_24pt-Medium.ttf') format('truetype');
}
